import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import MetaWp from "../components/meta-wp"
import HeroCta from "../components/hero-cta"
import CharacterInfo from "../components/character-info"
import CharacterList from "../components/character-list"

const CharacterTemplate = ({ data }) => {
  const { wp, metaWp, allCharacters, characterAttributes } = data

  return (
    <React.Fragment>
      <MetaWp wp={metaWp} />

      <h1 className="is-vhidden">{wp.title}</h1>

      <HeroCta
        data={{
          published: true,
          type: `content`,
          label: wp.acf.type.name,
          title: wp.acf.shortName,
          backgroundMedia: wp.acf.heroMediaType,
          backgroundImage: wp.acf.heroBackgroundImage,
          backgroundVideoUrl: wp.acf.heroVideoUrl,
        }}
        displayHeader={true}
      />

      <CharacterInfo data={wp} allAttributes={characterAttributes.edges} />

      <CharacterList
        data={{
          published: true,
          filterType: wp.acf.type.termId,
          sectionHeading: wp.acf.type.name,
          comingSoonCharacter: true,
        }}
        allCharacters={allCharacters}
      />
    </React.Fragment>
  )
}

CharacterTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CharacterTemplate

export const pageQuery = graphql`
  query($id: String!) {
    ...MetaWpPost
    ...AllCharactersFragment

    wp: wordpressWpCharacter(id: { eq: $id }) {
      ...CharacterFragment
    }

    characterAttributes: allWordpressAcfCharacterAttributes {
      edges {
        node {
          wpId: wordpress_id
          acf {
            icon: character_attribute_icon {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 128) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
