import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

const Container = styled.section`
  padding: ${rem(80)} ${rem(40)};
  padding: ${rem(80)} 0;
  position: relative;

  @media ${props => props.theme.largeDown} {
    display: flex;
    flex-direction: column-reverse;
    padding: ${rem(25)} ${rem(40)} 0 ${rem(40)};
  }

  @media ${props => props.theme.mediumDown} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
`

const ContainerBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colorGreyDarkest};

  > * {
    width: 100%;
    height: 100%;
  }
`

const Image = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;

  @media ${props => props.theme.largeUp} {
    padding-top: ${rem(20)};
  }

  @media ${props => props.theme.largeDown} {
    position: static;
    max-height: 50vh;
    margin-top: ${rem(-200)};

    img {
      object-fit: cover;
    }
  }

  > div {
    // ${props => props.theme.gridContainer()}

    padding: 0;
    height: 100%;

    > * {
      width: 50%;
      height: 100%;

      @media ${props => props.theme.largeDown} {
        width: 100%;
      }
    }
  }
`

const Box = styled.div`
  ${props => props.theme.gridContainer()}

  min-height: ${rem(420)};
  display: flex;
  justify-content: flex-end;
  background-color: ${props => props.theme.colorBlack};
  clip-path: polygon(2% 0%, 100% 0%, 98% 100%, 0% 100%);

  @media ${props => props.theme.largeDown} {
    padding-bottom: ${rem(200)};
    padding-top: ${rem(30)};
    clip-path: polygon(1% 0%, 100% 0%, 99% 100%, 0% 100%);
  }

  @media ${props => props.theme.mediumDown} {
    clip-path: none;
  }
`

const BoxInfo = styled.div`
  width: 50%;
  padding: ${rem(60)} ${rem(60)} ${rem(90)};
  position: relative;
  z-index: 2;

  @media ${props => props.theme.largeDown} {
    width: 100%;
    padding-top: 0;
  }

  @media ${props => props.theme.smallDown} {
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
    padding-bottom: ${rem(40)};
  }

  > div {
    max-width: ${rem(480)};
  }
`

const BoxBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  filter: grayscale(1);
  opacity: 0.4;
  mix-blend-mode: luminosity;

  @media ${props => props.theme.largeDown} {
    height: 100vh;
    top: auto;
    bottom: 0;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      100deg,
      transparent 30%,
      ${props => props.theme.colorBlack} 50%
    );

    @media ${props => props.theme.largeDown} {
      background-image: linear-gradient(
        to top,
        transparent 0%,
        ${props => props.theme.colorBlack} 75%
      );
      top: auto;
      bottom: 0;
    }
  }

  > * {
    width: 55%;
    height: 100%;

    @media ${props => props.theme.largeDown} {
      width: 100%;
    }
  }
`

const Heading = styled.h2`
  margin-bottom: ${rem(10)};

  span {
    margin-bottom: ${rem(10)};
    display: block;
    color: ${props => props.theme.colorYellow};
  }

  b {
  }
`

const Introduction = styled.div.attrs({ className: `styled-p-large` })`
  margin-top: ${rem(20)};

  p {
    margin-top: ${rem(20)};
  }
`

const Description = styled.div.attrs({ className: `styled-p-small` })`
  margin-top: ${rem(20)};
  opacity: 0.75;

  p {
    margin-top: ${rem(20)};
  }
`

const Attributes = styled.ul`
  margin-top: ${rem(30)};

  li {
    margin-bottom: ${rem(20)};
    display: inline-block;
    width: 100%;
  }

  figure {
    background: rgba(0, 255, 186, 0.1);
    border: 1px solid rgba(0, 255, 186, 0.2);
    width: ${rem(70)};
    height: ${rem(70)};
    float: left;
    margin-right: ${rem(15)};
    margin-bottom: ${rem(10)};
    text-align: center;
  }

  h3 {
    color: ${props => props.theme.colorTurquoise};
  }

  p {
    font-size: ${rem(14)};
    opacity: 0.75;
  }
`

const CharacterInfo = ({
  data: {
    acf: {
      firstName,
      lastName,
      titleTagline,
      introduction,
      description,
      attributes,
      portraitImage,
      portraitBackground,
      backgroundImage,
    },
  },
  allAttributes,
}) => {
  const fullName = `${firstName} ${lastName}`.trim()

  return (
    <Container>
      {portraitImage && portraitImage.localFile && (
        <Image>
          <div>
            <Img
              fluid={portraitImage.localFile.childImageSharp.fluid}
              alt={fullName}
              objectFit="cover"
              objectPosition="100% 0%"
            />
          </div>
        </Image>
      )}

      <Box>
        <BoxInfo>
          <div>
            <Heading>
              {titleTagline && (
                <span className="styled-h3">{titleTagline}</span>
              )}

              {fullName && <b className="styled-h2">{fullName}</b>}
            </Heading>

            {introduction && (
              <Introduction
                dangerouslySetInnerHTML={{ __html: introduction }}
              />
            )}

            {description && (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            )}

            {attributes && attributes.length > 0 && (
              <Attributes aria-label="Attributes">
                {attributes.map((attribute, i) => {
                  const attributeTerm = allAttributes.find(
                    ({ node }) => node.wpId === attribute.termId
                  )

                  return (
                    <li key={i}>
                      {attributeTerm.node.acf.icon &&
                        attributeTerm.node.acf.icon.localFile && (
                          <figure>
                            <Img
                              fluid={
                                attributeTerm.node.acf.icon.localFile
                                  .childImageSharp.fluid
                              }
                              alt={attribute.name}
                              objectFit="cover"
                              objectPosition="100% 0%"
                            />
                          </figure>
                        )}
                      <h3 className="styled-h4">{attribute.name}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: attribute.description,
                        }}
                      />
                    </li>
                  )
                })}
              </Attributes>
            )}
          </div>
        </BoxInfo>

        {portraitBackground && portraitBackground.localFile && (
          <BoxBackground>
            <Img
              fluid={portraitBackground.localFile.childImageSharp.fluid}
              alt=""
              role="presentation"
            />
          </BoxBackground>
        )}
      </Box>

      <ContainerBackground>
        {backgroundImage && backgroundImage.localFile && (
          <Img
            fluid={backgroundImage.localFile.childImageSharp.fluid}
            alt=""
            role="presentation"
          />
        )}
      </ContainerBackground>
    </Container>
  )
}

CharacterInfo.propTypes = {
  data: PropTypes.object,
  allAttributes: PropTypes.array,
}

export default CharacterInfo
